import { render, staticRenderFns } from "./DonationSetAmount.vue?vue&type=template&id=54067340&scoped=true&"
import script from "./DonationSetAmount.vue?vue&type=script&lang=js&"
export * from "./DonationSetAmount.vue?vue&type=script&lang=js&"
import style0 from "./DonationSetAmount.vue?vue&type=style&index=0&id=54067340&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54067340",
  null
  
)

export default component.exports