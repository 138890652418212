<template>
  <div class="feed-body">
    <div class="feed-contents">
      <div class="d-flex flex-row w-100 justify-between mt-5 mb-3">
        <span class="white--text font-30 app-medium-font">{{
          $t("donate")
        }}</span>
      </div>
      <div class="d-flex flex-row justify-start w-100 mb-5">
        <span class="white--text font-18 app-regular-font">
          Set your donation amount
        </span>
      </div>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-10"
      />
      <div v-else class="w-100">
        <v-card @click="goToProfile" class="rounded-lg mx-2">
          <div
            class="d-flex align-center justify-start h-100 pa-5 d-flex flex-row"
            v-if="agency"
          >
            <agency-avatar
              :img="agency.midThumbnail ? agency.midThumbnail : agency.image"
              :name="agency.name"
              :size="60"
            />
            <span
              class="primary-color font-20 app-bold-font text-left text-ellipsis break-word line-height-15 force-height-30 d-flex align-center ml-4"
            >
              {{ agency.name }}
            </span>
          </div>
        </v-card>
        <div class="d-flex w-100 pt-4">
          <div style="width: 25%" class="pa-2">
            <v-btn
              block
              :style="
                amount == 50 ? 'background-color: #084468; color: white;' : ''
              "
              @click="amount = '50.00'"
            >
              $50
            </v-btn>
          </div>
          <div style="width: 25%" class="pa-2">
            <v-btn
              block
              :style="
                amount == 100 ? 'background-color: #084468; color: white;' : ''
              "
              @click="amount = '100.00'"
            >
              $100
            </v-btn>
          </div>
          <div style="width: 25%" class="pa-2">
            <v-btn
              block
              :style="
                amount == 500 ? 'background-color: #084468; color: white;' : ''
              "
              @click="amount = '500.00'"
            >
              $500
            </v-btn>
          </div>
          <div style="width: 25%" class="pa-2">
            <v-btn
              block
              :style="
                amount == 1000 ? 'background-color: #084468; color: white;' : ''
              "
              @click="amount = '1000.00'"
            >
              $1000
            </v-btn>
          </div>
        </div>
        <div class="d-flex w-100 px-2">
          <v-text-field
            label="Amount"
            solo
            flat
            outlined
            v-model="amount"
            style="font-family:'Poppins-Regular';"
            type="number"
            append-icon="mdi-currency-usd"
            @blur="onRoundAmount"
            @keyup.enter="onRoundAmount"
          />
        </div>
        <div class="d-flex w-100 py-4 px-2">
          <v-card class="w-100">
            <v-card-text>
              <v-textarea
                label="Description"
                solo
                flat
                outlined
                v-model="description"
                style="font-family:'Poppins-Regular';"
              />
            </v-card-text>
          </v-card>
        </div>
        <div class="pa-2">
          <v-btn @click="goToPayment" block>
            Next
          </v-btn>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
export default {
  components: { AgencyAvatar },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      agency: null,
      amount: null,
      description: "",
    };
  },
  methods: {
    ...mapActions({
      getAgency: "agency/getAgency",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    goToProfile() {
      let routeData = this.$router.resolve({
        name: "agency-profile-details",
        params: { id: this.$route.query.id },
      });
      window.open(routeData.href, "_blank");
    },
    onRoundAmount() {
      try {
        if (!this.amount) return;
        this.amount = parseFloat(`${this.amount}`).toFixed(2);
      } catch (error) {
        console.log(error);
      }
    },
    goToPayment() {
      if (this.amount && this.$route.query.id) {
        this.$router.replace({
          name: "donation-payment",
          query: {
            id: this.$route.query.id,
            amount: this.amount,
            description: this.description,
          },
        });
      }
    },
  },
  mounted() {
    this.loading = true;
    this.getAgency(this.$route.query.id)
      .then((res) => {
        this.loading = false;
        this.agency = res;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
        this.$router.replace({ name: "donation-select-agency" });
      });
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
